import router from './router'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import VWave from 'v-wave'
import ButtonComponent from '@/components/buttons/ButtonComponent.vue'
import InputErrorComponent from '@/components/input/InputErrorComponent.vue'
import ErrorComponent from '@/components/input/ErrorComponent.vue'
import Popper from "vue3-popper";
import './registerServiceWorker'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import './registerServiceWorker'

// import { fas } from '@fortawesome/free-solid-svg-icons'

// library.add(fas)

import { faXmark, faClipboard, faCheck, faTrash, faFileArrowDown, faFileImage, faFilePdf, faQuestion, faTriangleExclamation, faClock, faCloudArrowUp, faFileUpload, faRightFromBracket, faRightToBracket } from '@fortawesome/free-solid-svg-icons'

library.add(faXmark)
library.add(faClipboard)
library.add(faCheck)
library.add(faTrash)
library.add(faFileArrowDown)
library.add(faFileImage)
library.add(faFilePdf)
library.add(faQuestion)
library.add(faTriangleExclamation)
library.add(faClock)
library.add(faCloudArrowUp)
library.add(faFileUpload)
library.add(faRightFromBracket)
library.add(faRightToBracket)

const pinia = createPinia()

createApp(App)
    .component('Popper', Popper)
    .use(VWave).component('font-awesome-icon', FontAwesomeIcon)
    .component('input-error-component', InputErrorComponent)
    .component('error-component', ErrorComponent)
    .component('button-component', ButtonComponent)
    .use(pinia)
    .use(router)
    .mount('#app')
