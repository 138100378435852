<template>
  <div v-if="modalStore.modalInfo != null">
    <div class="modal fade show" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog"
      style="display:block">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{modalStore.modalInfo.title}}</h5>
            <button type="button" @click="cancel()" class="btn-close"></button>
          </div>
          <div class="modal-body">
            {{modalStore.modalInfo.message}}
          </div>
          <div class="modal-footer">
            <button type="button" @click="ok()" :class="'btn btn-'+modalStore.modalInfo.variant">Ok</button>
            <button type="button" @click="cancel()" class="btn btn-secondary">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useModalStore } from "@/store/modal.store"
const modalStore = useModalStore();
function cancel() {
  modalStore.modalInfo.cancelListener()
  modalStore.clear();
}
function ok() {
  modalStore.modalInfo.okListener()
  modalStore.clear();
}
</script>

<style scoped>

</style>