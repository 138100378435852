import { watchEffect } from "vue";
import { useDocStore } from "../store/doc.store"
import {
  FILE_PENDING,
  FILE_IN_PROGRESS,
  FILE_COMPLETE,
} from "../store/doc.store";
import DocProgress from "../store/model/DocProgress"
import { BASE_URL, PARALLEL_UPLOADS } from "../constants"
import defaultClient from './defaultClient';
import { storeToRefs } from "pinia";
export default () => {
  const { xmlHttpRequest } = defaultClient()
  const docStore = useDocStore()
  const docRefs = storeToRefs(docStore)

  function getActiveCount() {
    const docs = docRefs.fileProgress.value
    let count = 0
    for (let i = 0; i < docs.length; i++) {
      if (docs[i].status == FILE_IN_PROGRESS) {
        count++
      }
    }
    return count
  }

  function getFirstPendingDoc() {
    return docRefs.fileProgress.value.find((element: DocProgress) => element.status == FILE_PENDING)
  }

  function updateDoc(index: number, status: number, progress: number) {
    const tmp = docRefs.fileProgress.value.findIndex((element: DocProgress) => element.id == index)
    if (tmp != -1) {
      const doc = docRefs.fileProgress.value[tmp]
      docRefs.fileProgress.value[tmp] = new DocProgress(doc.id, doc.docs, status, progress)
    }
  }

  function deleteDoc(index: number) {
    docRefs.fileProgress.value = docRefs.fileProgress.value.filter((element: DocProgress) => element.id != index)
  }

  function sendRequest(request: XMLHttpRequest, formData: FormData, progressListener: any) {
    return new Promise((resolve, reject) => {
      request.upload.addEventListener("progress", progressListener)
      request.onload = () => {
        if (request.status >= 200 && request.status < 300) {
          resolve(request.response);
        } else {
          reject(request.statusText);
        }
      };
      request.onerror = () => reject(request.statusText);
      request.send(formData)
    });
  }

  watchEffect(async () => {
    try {
      const activeCount = getActiveCount()
      if (activeCount >= PARALLEL_UPLOADS) {
        console.log("Too much uploads")
        return
      }

      const firstPendingDoc = getFirstPendingDoc()
      if (!firstPendingDoc) {
        console.log("Got not pending doc")
        return
      }

      const file = firstPendingDoc.docs;
      const docId = firstPendingDoc.id;

      updateDoc(docId, FILE_IN_PROGRESS, 0)

      try {
        const formData = new FormData();
        formData.append(file.name, file);
        formData.append("resizeImage", JSON.stringify(true));
        formData.append("documentDetail", JSON.stringify({}));
        const request = xmlHttpRequest("POST", BASE_URL + "/documents/")
        await sendRequest(request, formData, (progress: any) => {
          updateDoc(docId, FILE_IN_PROGRESS, 100 / progress.total * progress.loaded)
        })
      } catch (exc) {
        console.log(exc)
      } finally {
        docStore.load(true)
      }


      updateDoc(docId, FILE_COMPLETE, 0)

      await new Promise(res => setTimeout(res, 3000));
      deleteDoc(docId)
    } catch (err) {
      console.log(err);
    }
  })
};