import { defineStore } from 'pinia'
import { BASE_URL } from "../constants"
import { useProgressStore } from './progress.store'
import defaultClient from '../utils/defaultClient';
import User from './model/User';

function _base64ToArrayBuffer(base64: string) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}

// Create a new store instance.
export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        user: null as User | null,
        token: null as string | null,
        privateKey: null as Uint8Array | null,
        publicKey: null as Uint8Array | null,
    }),
    actions: {
        async login(username: string, password: string) {
            const { fetchDefault } = defaultClient()
            const progressStore = useProgressStore()
            progressStore.progress(true)
            try {
                const result = await fetchDefault(BASE_URL + "/users/auth", {
                    method: "POST",
                    body: JSON.stringify({ username: username, password: password }),
                    headers: new Headers({ 'content-type': "application/json" })
                })
                this.token = result.headers.get("token")
                this.user = await result.json()

                const sodium = (window as { loadedSodium: any } & Window & typeof globalThis).loadedSodium
                const hashNonce = _base64ToArrayBuffer(this.user!!.hashNonce!!)
                this.publicKey = _base64ToArrayBuffer(this.user!!.publicKey!!)
                const dataNonce = _base64ToArrayBuffer(this.user!!.dataNonce!!)
                const encryptedPrivateKey = _base64ToArrayBuffer(this.user!!.encryptedPrivateKey!!)
                const textEncoder = new TextEncoder()
                const start = Date.now()
                const hashed_password = sodium.crypto_pwhash(32, textEncoder.encode(password), hashNonce, sodium.crypto_pwhash_OPSLIMIT_INTERACTIVE, sodium.crypto_pwhash_MEMLIMIT_INTERACTIVE, sodium.crypto_pwhash_ALG_ARGON2ID13);
                const end = Date.now()
                console.log("Argon took: " + (end - start) + " ms.")
                this.privateKey = sodium.crypto_secretbox_open_easy(encryptedPrivateKey, dataNonce, hashed_password)
            }
            finally {
                progressStore.progress(false)
            }
        },
        logout() {
            this.user = null
            this.token = null
            this.privateKey = null
        }
    }
})