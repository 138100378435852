import { useAuthStore } from "@/store/auth.store";

// function _base64ToArrayBuffer(base64) {
//   var binary_string = window.atob(base64);
//   var len = binary_string.length;
//   var bytes = new Uint8Array(len);
//   for (var i = 0; i < len; i++) {
//     bytes[i] = binary_string.charCodeAt(i);
//   }
//   return bytes;
// }

// function bytesArrToBase64(arr) {
//   const abc = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"; // base64 alphabet
//   const bin = n => n.toString(2).padStart(8, 0); // convert num to 8-bit binary string
//   const l = arr.length
//   let result = '';

//   for (let i = 0; i <= (l - 1) / 3; i++) {
//     let c1 = i * 3 + 1 >= l; // case when "=" is on end
//     let c2 = i * 3 + 2 >= l; // case when "=" is on end
//     let chunk = bin(arr[3 * i]) + bin(c1 ? 0 : arr[3 * i + 1]) + bin(c2 ? 0 : arr[3 * i + 2]);
//     let r = chunk.match(/.{1,6}/g).map((x, j) => j == 3 && c2 ? '=' : (j == 2 && c1 ? '=' : abc[+('0b' + x)]));
//     result += r.join('');
//   }

//   return result;
// }

export default () => {
  const authStore = useAuthStore()
  return {
    decodeBuffer: (arrayBuffer: ArrayBuffer) => {
      const sodium = (window as { loadedSodium: any } & Window & typeof globalThis).loadedSodium
      const buffer = new Uint8Array(arrayBuffer)
      const decrypted = sodium.crypto_box_seal_open(buffer, authStore.publicKey, authStore.privateKey)

      // var length = decrypted[0]
      // length |= decrypted[1] << 8
      // length |= decrypted[2] << 16
      // length |= decrypted[3] << 24

      const sliced = decrypted.slice(4)
      return sliced as Uint8Array
    },
  };
};