import { defineStore } from 'pinia'

// Create a new store instance.
export const useProgressStore = defineStore({
    id: "progress",
    state: () => ({
        inProgress: false,
    }),
    actions: {
        progress(inProgress: boolean) {
            this.inProgress = inProgress
        }
    }
})