<template>
  <transition name="fade">
    <div v-if="progressStore.inProgress == true" style="
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000000a0;
        z-index: 10500;
      ">
      <div class="spinner-grow" role="status" style="position: absolute; top: 50%; left: 50%">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </transition>
</template>

<script setup>
const { useProgressStore } = require("@/store/progress.store");
const progressStore = useProgressStore();
</script>

<style scoped>
.container {
  height: auto;
  margin: 10px;
  width: 100px;
  bottom: 0px;
  position: fixed;
  z-index: 10500;
  background-color: #000000;
}
</style>