<template>
  <transition name="fade">
    <div v-if="authStore.user && path == '/'">
      <div class="container">
        <transition-group name="fade">
          <upload-status-component v-for="file in docStore.sortedFileProgress" :key="file.id" :file="file" />
        </transition-group>
        <upload-icon-component @onClick="showDialog" @onDrop="dropped" />
        <input ref="inputRef" type="file" style="display: none" multiple @change="upload" />
      </div>
    </div>
  </transition>
</template>

<script setup>
import UploadIconComponent from "@/components/upload/UploadIconComponent.vue";
import UploadStatusComponent from "@/components/upload/UploadStatusComponent.vue";
import { useDocStore } from "@/store/doc.store";
import { useRoute } from "vue-router";
const { useAuthStore } = require("@/store/auth.store");
const { ref, computed } = require("vue");

const authStore = useAuthStore();
const docStore = useDocStore();
const inputRef = ref(null);
function showDialog() {
  inputRef.value.click();
}
function upload() {
  console.log("Uploading...");
  docStore.uploadFiles(inputRef.value.files);
  inputRef.value.value = null;
}

function dropped(event) {
  docStore.uploadFiles(event.dataTransfer.files)
}

const route = useRoute()
const path = computed(() => route.path)
</script>

<style scoped>
.container {
  height: auto;
  width: 100px;
  bottom: 0px;
  position: fixed;
  z-index: 5;
}
</style>