import { BASE_URL } from "@/constants";
import { useAuthStore } from "@/store/auth.store";
import bufferUtils from "./bufferUtils";

// function _base64ToArrayBuffer(base64) {
//   var binary_string = window.atob(base64);
//   var len = binary_string.length;
//   var bytes = new Uint8Array(len);
//   for (var i = 0; i < len; i++) {
//     bytes[i] = binary_string.charCodeAt(i);
//   }
//   return bytes;
// }

// function bytesArrToBase64(arr) {
//   const abc = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"; // base64 alphabet
//   const bin = n => n.toString(2).padStart(8, 0); // convert num to 8-bit binary string
//   const l = arr.length
//   let result = '';

//   for (let i = 0; i <= (l - 1) / 3; i++) {
//     let c1 = i * 3 + 1 >= l; // case when "=" is on end
//     let c2 = i * 3 + 2 >= l; // case when "=" is on end
//     let chunk = bin(arr[3 * i]) + bin(c1 ? 0 : arr[3 * i + 1]) + bin(c2 ? 0 : arr[3 * i + 2]);
//     let r = chunk.match(/.{1,6}/g).map((x, j) => j == 3 && c2 ? '=' : (j == 2 && c1 ? '=' : abc[+('0b' + x)]));
//     result += r.join('');
//   }

//   return result;
// }

import { CacheType, Cache } from "@/utils/cache"

const cacheMap = new Map<CacheType, Cache>();
cacheMap.set(CacheType.Thumbnail, new Cache("Thumbnail", 1024 * 1024 * 5))
cacheMap.set(CacheType.Download, new Cache("Download", 1024 * 1024 * 5))
cacheMap.set(CacheType.Image, new Cache("Image", 1024 * 1024 * 15))

let lastLog = 0

export default () => {
  const { decodeBuffer } = bufferUtils();
  const authStore = useAuthStore()
  async function fetchDefault(url: string, options: RequestInit) {
    if (!options.headers) {
      options.headers = new Headers();
    }
    const headers = options.headers as Headers;
    headers.append("X-api-key", "sdoiirnhaksfoewizufkjhadsfoiwezalkofjhdaosifheoiwhf")
    if (authStore.token) {
      headers.append("Authorization", "Bearer " + authStore.token)
    }
    headers.delete("cache-control");
    const result = await fetch(url, options)

    if (result.ok) {
      return result;
    } else {
      throw new Error("Failed!!! " + result.status + " " + result.statusText)
    }
  }
  return {
    CacheType,
    fetchDefault,
    xmlHttpRequest: (method: string, url: string) => {
      const request = new XMLHttpRequest()
      request.open(method, url)
      request.withCredentials = true;
      request.setRequestHeader("X-api-key", "sdoiirnhaksfoewizufkjhadsfoiwezalkofjhdaosifheoiwhf")
      if (authStore.token) {
        request.setRequestHeader("Authorization", "Bearer " + authStore.token)
      }
      return request
    },
    loadAndDecryptResource: async (resource: string, cacheType: CacheType) => {
      try {
        const cache = cacheMap.get(cacheType)!
        const cachedValue = cache.get(resource);
        if (cachedValue != null) {
          console.log("Returning cached value")
          return URL.createObjectURL(
            new Blob([cachedValue.buffer])
          );
        } else {
          console.log("Cache miss");
        }

        const result = await fetchDefault(
          BASE_URL + "/resources/" + resource,
          {
            method: "GET",
          },
        );
        await new Promise(res => setTimeout(res, 3000));
        const buffer = await result.arrayBuffer();
        const content = decodeBuffer(buffer);
        cache.put(resource, content)

        return URL.createObjectURL(
          new Blob([content.buffer])
          //new Blob([content.buffer], { type: "image/jpeg" } /* (1) */)
        );
      } finally {
        if (Date.now() - lastLog > 30000) {
          for (const c of cacheMap.entries()) {
            console.log(c[1].toString())
          }
          lastLog = Date.now()
        }
      }
    }
  };
};