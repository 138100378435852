export default class DocProgress {
    id: number;
    docs: File;
    status: number;
    progress: number;

    constructor(id: number, docs: File, status: number, progress: number) {
        this.id = id;
        this.docs = docs;
        this.status = status;
        this.progress = progress;
    }
}