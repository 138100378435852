<template>
  <div v-if="!loading" :title="title" @mousedown="(event) => { updateState('down', event, true, null, false) }"
    @mouseleave="(event) => { updateState('out', event, false, false, false) }"
    @mouseover="(event) => { updateState('over', event, null, true, false) }"
    @mouseup="(event) => { updateState('up', event, false, null, false) }"
    @click="(event) => { updateState('click', event, false, false, true); emit('onClick') }" v-wave="{
    color: 'white',
    }" style="border-radius: 25%; width: fit-content;">
    <font-awesome-icon class="button" :icon="'fa-solid ' + icon" :size="size" :style="buttonStyle"
      :fixed-width="fixedWidth" />
  </div>
  <div v-else>
    <div class="spinner-border text-primary" role="status" style="position: absolute">
    </div>
    <font-awesome-icon class="button" :icon="'fa-solid ' + icon" :size="size" :style="buttonStyle"
      :fixed-width="fixedWidth" style="visibility: hidden" />
  </div>
</template>

<script setup>
const { ref, watchEffect } = require("vue");

const props = defineProps({
  loading: { default: false },
  icon: { type: String },
  title: { type: String, default: "" },
  color: { type: String, default: "white" },
  colorHover: { type: String, default: "#123456" },
  colorPressed: { type: String, default: "blue" },
  size: { type: String, default: "1x" },
  "fixedWidth": { type: Boolean, default: false }
});
const emit = defineEmits(["onClick"]);
const clicked = ref(false);
const hover = ref(false);
const buttonStyle = ref({ color: props.color, padding: '12px', cursor: 'pointer', filter: "drop-shadow(0.5px 0.5px 5px black)" })
watchEffect(() => {
  if (clicked.value) {
    buttonStyle.value.color = props.colorPressed
  } else if (hover.value) {
    buttonStyle.value.color = props.colorHover
  } else {
    buttonStyle.value.color = props.color
  }
})
function updateState(tmp, event, isClicked, isHover, stopPropagation = false) {
  if (isClicked === true || isClicked === false) {
    clicked.value = isClicked
  }
  if (isHover === true || isHover === false) {
    hover.value = isHover
  }
  if (stopPropagation) {
    event.stopPropagation()
    event.preventDefault()
  }
}
</script>

<style scoped>

</style>