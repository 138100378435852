import { defineStore } from 'pinia'
import ModalInfo from './model/ModalInfo'

// Create a new store instance.
export const useModalStore = defineStore({
    id: "modal",
    state: () => ({
        modalInfo: null as ModalInfo | null,
    }),
    actions: {
        clear() {
            this.modalInfo = null
        },
        show(modalInfo: ModalInfo) {
            this.modalInfo = modalInfo
        }
    }
})