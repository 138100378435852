export enum CacheType {
    Thumbnail,
    Download,
    Image
}

class Container {
    data: Uint8Array
    age = Date.now()
    key: string

    constructor(key: string, data: Uint8Array) {
        this.key = key
        this.data = data
    }
}

export class Cache {
    hits = 0
    misses = 0
    currentSize = 0
    maxSize: number
    name: string

    constructor(name: string, maxSize: number = 1024 * 1024 * 15) {
        this.name = name;
        this.maxSize = maxSize
    }

    cache = new Map<string, Container>()

    clear() {
        const array = [...this.cache.values()]
        array.sort((a, b) => a.age - b.age)
        let i = 0;
        while (this.currentSize > this.maxSize && i < array.length) {
            const tmp = array[i];
            this.cache.delete(tmp.key)
            this.currentSize -= tmp.data.byteLength
            i++;
        }
    }

    put(key: string, value: Uint8Array) {
        this.currentSize += value.byteLength
        this.cache.set(key, new Container(key, value))

        if (this.currentSize >= this.maxSize) {
            console.log("Cache exeeded: " + this.currentSize / 1024 / 1024 + "mb of " + this.maxSize / 1024 / 1024 + "mb")
            this.clear()
        }
    }

    get(key: string): Uint8Array | null {
        const res = this.cache.get(key)
        if (res != null) {
            this.hits++
            res.age = Date.now()
            return res.data
        }
        this.misses++
        return null
    }

    toString() {
        return "[" + this.name + "] Misses: " + this.misses + " hits: " + this.hits + " Items: " + this.cache.size + " " + this.currentSize / 1024 / 1024 + "mb of " + this.maxSize / 1024 / 1024 + "mb"
    }
}