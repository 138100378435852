import { defineStore } from 'pinia'
import { BASE_URL } from "../constants"
import { useProgressStore } from './progress.store'
import defaultClient from '../utils/defaultClient'
import DocProgress from "../store/model/DocProgress"
import Document from './model/Document'

export const FILE_IN_PROGRESS = 0
export const FILE_PENDING = 1
export const FILE_COMPLETE = 2
export const FILE_FAILED = 3

// Create a new store instance.
export const useDocStore = defineStore({
    id: "doc",
    state: () => ({
        docs: null as Document[] | null,
        fileProgress: [] as DocProgress[],
        fileUploadIndex: 0,
        openDoc: null as Document | null
    }),
    getters: {
        sortedFileProgress: (state) => {
            const sorted = state.fileProgress.sort((lhs, rhs) => {
                if (lhs.status == rhs.status) {
                    return lhs.id - rhs.id
                }
                return rhs.status - lhs.status
            })
            return sorted
        }
    },
    actions: {
        async load(force = false) {
            if (!force && this.docs != null) {
                return
            }
            const { fetchDefault } = defaultClient()
            const progressStore = useProgressStore()
            if (!force) {
                progressStore.progress(true)
            }
            try {
                const result = await fetchDefault(BASE_URL + "/documents/", {
                    method: "GET",
                    headers: new Headers({
                        'content-type': "application/json",
                    })
                })
                this.docs = await result.json()
            } finally {
                if (!force) {
                    progressStore.progress(false)
                }
            }
        },
        uploadFiles(files: File[]) {
            try {
                for (const file of files) {
                    if (!this.fileProgress.find((element) => element.docs == file)) {
                        this.fileProgress.push(new DocProgress(this.fileUploadIndex, file, FILE_PENDING, 0))
                        this.fileUploadIndex++
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        async deleteDoc(doc: Document) {
            const { fetchDefault } = defaultClient()
            try {
                if (this.openDoc == doc) {
                    this.openDoc = null
                }
                await fetchDefault(BASE_URL + "/documents/" + doc._id, {
                    method: "DELETE",
                })
            } finally {
                this.docs = this.docs!!.filter((element) => element._id != doc._id)
            }
        },
        async mergeDoc(parentDocId: string, childDocId: string) {
            const { fetchDefault } = defaultClient()
            await fetchDefault(BASE_URL + "/documents/" + parentDocId + "/add/" + childDocId, {
                method: "POST",
            })
        }
    }
})